export const SET_LOGIN = "SET_LOGIN";
export const SET_LOGOUT = "SET_LOGOUT";
export const SET_LOADING = "SET_LOADING";
export const RESET_USER_DATA = "RESET_USER_DATA";
export const ERROR_LOGIN = "ERROR_LOGIN";
export const ERROR_LOGOUT = "ERROR_LOGOUT";
export const RESET_ERROR_LOGIN_DATA = "RESET_ERROR_LOGIN_DATA";

export const SET_REG_MESSGAE = "SET_REG_MESSGAE";
export const SET_QUESTIONS = "SET_QUESTIONS";
export const RESET_QUESTIONS = "RESET_QUESTIONS";

export const SET_TIME_LEFT = "SET_TIME_LEFT";

export const SET_QUIZ_START = "SET_QUIZ_START";
export const SET_QUIZ_LEVEL = "SET_QUIZ_LEVEL";
export const SET_FINAL_RESULT = "SET_FINAL_RESULT";

export const LEADERBOARD_FETCH_REQUEST = "LEADERBOARD_FETCH_REQUEST";
export const LEADERBOARD_FETCH_SUCCESS = "LEADERBOARD_FETCH_SUCCESS";
export const LEADERBOARD_FETCH_FAILD = "LEADERBOARD_FETCH_FAILD";
