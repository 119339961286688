import React from "react";

const index = () => {
  return (
    <div className="game-rules">
      <h3>
        ‘গণতন্ত্র উৎসবঃ পলিটিক্স ম্যাটারস’ অনলাইন কুইজ প্রতিযোগিতার অংশগ্রহণের
        নিয়ম ও শর্তাবলীঃ
      </h3>
      <li>
        কুইজবাজদের বয়সসীমা: ১৮ – ৩৫। যে কেউ কুইজ খেলতে পারবেন, তবে পুরস্কার
        জিততে পারবেন শুধুমাত্র ১৮ থেকে ৩৫ বছর বয়সের বাংলাদেশি নাগরিক।
      </li>
      <li>
        ১৬ জুন থেকে ১৫ ডিসেম্বর ২০২১ পর্যন্ত প্রতিযোগিতায় অংশগ্রহণ করা যাবে।
      </li>
      <li>
        প্রতি মাসেই থাকবে নতুন প্রতিযোগিতা: প্রতি মাসের প্রথম দিনেই নতুন কুইজের
        বিষয় ও প্রশ্ন নির্ধারণ করে কারণ বাংলাদেশ আমার ওয়েবসাইটে কুইজ মেনুতে
        আপলোড করা হবে এবং ‘কারণ বাংলাদেশ আমার’ ফেইসবুক পেইজ ও{" "}
        <a href="https://www.facebook.com/groups/718296318974660">গ্রুপে</a>{" "}
        কুইজের বিষয় ঘোষণার মাধ্যমে প্রতিযোগিতায় অংশগ্রহণের জন্য আহ্বান জানানো
        হবে।
      </li>
      <li>
        কুইজ সংক্রান্ত সমস্ত তথ্য পেতে{" "}
        <a href="https://www.facebook.com/groups/718296318974660">
          ‘কারণ বাংলাদেশ আমার’ ফেইসবুক গ্রুপের{" "}
        </a>{" "}
        সদস্য হতে হবে।
      </li>
      <li>কুইজ খেলার জন্য কারণ বাংলাদেশ আমার ওয়েবসাইটে লগ ইন করতে হবে।</li>
      <li>
        ফেসবুক ও গুগল একাউন্ট ব্যবহার করে অথবা ওয়েবসাইটে সরাসরি নিবন্ধনের
        মাধ্যমে এই ওয়েবসাইটে লগ ইন করা যাবে।
      </li>
      <li>
        একজন প্রতিযোগী একটি নিবন্ধিত আইডি ব্যবহার করে কেবল একবার খেলতে পারবেন।
      </li>
      <li>
        প্রত্যেক প্রতিযোগীকে সঠিক তথ্য প্রদানের মাধ্যমে প্রতিযোগিতায় অংশগ্রহণ
        করতে হবে। বিজয়ীদের পুরস্কার প্রদানের আগে নিবন্ধনে ব্যবহৃত সকল তথ্য যাচাই
        করা হবে।
      </li>
      <li>খেলায় জিততে হলে সবগুলো ধাপের কুইজে অবশ্যই অংশ নিতে হবে।</li>
      <li>নির্ধারিত সময়ের মধ্যে প্রতিটি প্রশ্নের উত্তর প্রদান করতে হবে।</li>
      <li>প্রথম প্রশ্নের জন্য সময় ৬০ সেকেন্ড। </li>
      <li>দ্বিতীয় প্রশ্নের জন্য সময় ৫৫ সেকেন্ড। </li>
      <li>তৃতীয় প্রশ্নের জন্য সময় ৫০ সেকেন্ড। </li>
      <li>
        খেলা শেষে প্রতিযোগী তার স্কোর এবং লিডারবোর্ডে তার অবস্থান দেখতে পারবেন।
      </li>
      <li>
        সকল প্রশ্নের একাধিক সঠিক উত্তরদাতা থাকলে দ্রুততম সময়ের সঠিক উত্তরদাতা
        বিজয়ী হবেন। প্রতি মাসে সর্বোচ্চ সেরা ৩ উত্তরদাতা পুরস্কৃত হবেন এবং সেরা
        ১০ জন সার্টিফিকেট পাবেন।
      </li>
      <li>প্রয়োজনে লটারির ভিত্তিতে সেরা ৩ বিজয়ী নির্ধারণ করা হবে। </li>
      <li>
        বিজয়ীদের নাম পরবর্তী মাসের প্রথম ১৫ দিনের মধ্যে কারণ বাংলাদেশ আমার
        ফেইসবুক পেইজ ও গ্রুপে ঘোষণা করা হবে।{" "}
      </li>
      <li>
        প্রতিযোগিতা সংক্রান্ত যেকোনো বিষয়ে আয়োজকের সিদ্ধান্তই চূড়ান্ত বলে
        বিবেচিত হবে।
      </li>
      <li>
        ডেমোক্রেসি ইন্টারন্যশনাল, ইউএসএআইডি, ইউকেএইড বা বিটনিকের সাথে সংশ্লিষ্ট
        ব্যক্তি বা তাঁদের পরিবারের কোন সদস্য এই কুইজ প্রতিযোগিতায় অংশগ্রহণ করতে
        পারবেন না।
      </li>

      <h3 style={{ marginTop: "20px" }}>
        কী পুরস্কার থাকছে প্রতি মাসের সেরা কুইজবাজদের জন্য?{" "}
      </h3>

      <li> সেরা ১০ উত্তরদাতা একটি ই- সার্টিফিকেট পাবেন। </li>
      <li>
        সেরা ১০ এর মধ্যে থেকে সেরা ৩ কুইজবাজ গণতন্ত্র উৎসবের শুভেচ্ছা স্মারক
        পাবেন।{" "}
      </li>
      <li>ভলানটিয়ার হওয়ার সুযোগ। </li>
      <li>
        ই- সার্টিফিকেট কুইজ বিজয়ী নিজ ফেইসবুক প্রোফাইলে শেয়ার করতে পারবেন।{" "}
      </li>
      <li>
        বিজয়ীদের ছবি ও প্রয়োজনীয় তথ্য, হ্যাশট্যাগসহ কারণ বাংলাদেশ আমার ফেইসবুক
        পেইজে ও গ্রুপে প্রকাশ করা হবে।
      </li>
      <li>
        পলিটিক্স ম্যাটারস কুইজবাজ হ্যাশট্যাগ #PoliticsMatters #DemocracyFestival
        #Bangladesh50 #Bangladesh #quizbaj{" "}
      </li>
    </div>
  );
};

export default index;
